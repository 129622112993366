
import signupCarousel from '~/components/Molecules/OrganicSquad/SignupCarousel/SignupCarousel.vue'
import signupArguments from '~/components/Molecules/OrganicSquad/SignupArguments/SignupArguments.vue'
import { mapActions } from 'vuex'
import device from '~/mixins/device.js'

export default {
  mixins: [device],

  components: {
    signupIframe: () => import('~/components/Molecules/OrganicSquad/SignupIframe/SignupIframe.vue'),
    signupCarousel,
    signupArguments,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    embed: Object,
    content: Object,
    testArguments: Object,
    gtm: Object,
  },

  data() {
    return {
      dataLayer: {},
      device: null,
      showFooter: true,
      localizedExperiment: {
        es: () => this.$growthbook.isOn('10_corporate_website_home_desktop_spain_login_social'),
        'es-mx': () => this.$growthbook.isOn('11_corporate_website_home_desktop_mexico_login_social'),
        'es-co': () => this.$growthbook.isOn('12_corporate_website_home_desktop_colombia_login_social'),
      },
    }
  },

  methods: {
    ...mapActions('modalSignup', ['setSignupModalOpen']),

    closeModal() {
      document.body.classList.toggle('modal-open')
      this.setSignupModalOpen(false)

      this.$gtm.push({
        event: 'custom_event',
        custom_event_name: 'close_modal_sign_up_embed',
      })
    },
  },

  computed: {
    isTestModalArgumentsPT() {
      if (['pt-br'].includes(this.$i18n.locale) && this.isDesktop) {
        return this.$growthbook.isOn('9_corporate_website_home_desktop_brazil_modal_sidebar')
      }

      return false
    },

    isTestEsORNoContent() {
      if (this.localizedExperiment[this.$i18n.locale]) {
        return this.localizedExperiment[this.$i18n.locale]()
      }

      return !this.hasContent
    },

    isLoginSocial() {
      // always return login social if is pt-br and es-es
      if (['pt-br', 'es-es'].includes(this.$i18n.locale)) {
        return true
      }

      // return login social if is test login social ES's
      if (this.localizedExperiment[this.$i18n.locale]) {
        return this.localizedExperiment[this.$i18n.locale]()
      }

      return false
    },

    hasTest() {
      return true
    },

    locale() {
      return this.$i18n.locale
    },

    route() {
      return this.$route
    },

    hasContent() {
      return Boolean(this.content)
    },

    images() {
      return this.content.images.length > 0 ? this.content.images : []
    },

    getTitleClass() {
      return this.isLoginSocial ? 'signup-modal--titleTest' : 'signup-modal--title'
    },

    getSubtitleClass() {
      return this.isLoginSocial ? 'signup-modal--subtitleTest' : 'signup-modal--subtitle'
    },

    getCenterClass() {
      return ['pt-br'].includes(this.$i18n.locale) ? 'signup-modal__header--center' : ''
    },
  },
}
